/* a lot of bad practices with important that need to be done with css-in-js or migrate to tailwind for better dev experience*/
.App {
  text-align: center;
  font-family: 'Saira' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.css-11mde6h-MuiPaper-root {
  background-color: rgba(238, 244, 255, 0.333);
  /* color: #fff; */
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 14px 14px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.css-jtecce-MuiFormLabel-root.Mui-focused {
  /* color: #1976d2; */
  color: rgba(0, 0, 0, 0.87) !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
   border-color: rgba(0, 0, 0, 0.87);
   border-width: 0.5px;
}

.css-1i2bwbr-MuiTableCell-root {
  padding: 0px !important;
  font-family: 'Saira' !important;
}
.css-1tqi3ez {
  padding: 0px !important;
  font-family: 'Saira' !important;
}

.css-1ps6pg7-MuiPaper-root {
 height: 310px !important; 
}

.css-aoeo82 {
  height: 310px !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  margin-left: 40vw !important;
  font-weight: bolder !important;
  border: 2px solid #ED3838 !important;
  color: #ED3838 !important;
}
.css-1ujsas3 {
  margin-left: 40vw !important;
  font-weight: bolder !important;
  border: 2px solid #ED3838 !important;
  color: #ED3838 !important;
}

@media only screen and (max-width: 768px) {
  .css-9bd75r {
    position: absolute !important;
    margin-top: 0px !important;    
  }
  .css-a8h0np-MuiPaper-root-MuiAppBar-root{
    position: absolute !important;
    margin-top: 0px !important;
  }

  .css-ao8rxk {
    padding-top: 30px !important;  
  }

  .css-11mde6h-MuiPaper-root{
    padding-top: 30px !important;
  }

  .css-xz27vl-MuiPaper-root{
    padding-top: 30px !important;
  }

  .css-z5rkio{
    padding-top: 30px !important;
  }

  .css-zcmli0-MuiGrid-root {
    flex-direction: column !important;
    margin-left: -82px !important;
    flex-flow: column !important;
  }
  .css-15o8c4j {
    flex-direction: column !important;
    margin-top: 80px !important;
  }
  .css-17lflhs {
    flex-direction: column !important;
    margin-top: 80px !important;
    margin-bottom: 180px !important;
  }
  .css-12msj5r-MuiGrid-root{
    flex-direction: column !important;
    margin-top: 80px !important;
    margin-bottom: 180px !important;
  }
  .css-1tc76if-MuiGrid-root{
    flex-direction: column !important;
    margin-top: 80px !important;
  }
  .css-7uql24 {
    margin-left: -82px !important;
    flex-flow: column !important; 
  }

  .css-pry94a-MuiGrid-root{
    flex-flow: column !important;
    flex-direction: column !important;
    margin-top: 80px !important;
    margin-bottom: 180px !important;
  }

  .css-a26nzk {
    flex-flow: column !important;
    flex-direction: column !important;
    margin-top: 80px !important;
    margin-bottom: 180px !important;
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    position: absolute !important;
    width: 70% !important;
    margin-left: 30px !important;
}
.css-feqhe6 {
  position: absolute !important;
  width: 70% !important;
  margin-left: 30px !important;
}
.tss-9mgjcs-MUIDataTableToolbar-left.tss-9mgjcs-MUIDataTableToolbar-left {
  margin-top: 550px !important;
}
.tss-p4ugla-MUIDataTableToolbar-left.tss-p4ugla-MUIDataTableToolbar-left {
  margin-top: 550px !important;
}
.css-1nrlq1o-MuiFormControl-root {
  margin-top: 150px !important;
}
.css-13sljp9 {
  margin-top: 150px !important;
}
.css-3o29s4-MuiTypography-root{
  display: none;
}

.css-1hldzep {
  display: none;
}

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ED583E;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
